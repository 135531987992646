import React from 'react';
import styled from '@emotion/styled';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import { LABELS } from '../components/Constants';
import styles from './contact.module.css';
import Footer from '../components/Footer';
import Icons from '../components/Icons';

const Container = styled('div')`
  text-align: start;
`;

const AddressDiv = styled('div')`
  margin: 40px 0;
`;


const Contact = () => {
  return (
    <>
      <Header />
      <Hero
        heroImg='contact'
      />
      <Layout>
        <Container>
          <AddressDiv>
            <h3 className={styles.heading}>{LABELS.OFFICE}</h3>
            <p>{LABELS.COMPANY_NAME}</p>
            <p>{LABELS.ADDRESS_LINE_1}</p>
            <p>{LABELS.ADDRESS_LINE_2}</p>
            <p>{LABELS.ADDRESS_LINE_3}</p>
            <Icons />
          </AddressDiv>
        </Container>
      </Layout>
      <Footer />
    </>
  );
};

export default Contact;
